import { useState, useEffect} from "react";
import styled from "styled-components";
import logo from "../images/logo_clear.png";
import { Link, useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { FiMenu } from "react-icons/fi";
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import { supabase } from "../supabaseClient"; //UNCOMMENTED THIS TO USE IsSignIn code

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 90px;
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const MobileMenuContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100vw;
    background-color: white;
    padding: 10px;
    z-index: 1001;
  }
`;

const Logo = styled.img`
  height: 80px;
  margin-left: 20px;
  transition: transform 0.3s ease; /* Smooth scaling transition */
  
  &:hover {
    cursor: pointer;
    transform: scale(1.15); /* Scale the logo on hover */
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;

  // Hide the nav links on mobile screens
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: #111d57;
  font-size: 16px;
  font-weight: bold;
  font-family: "Source Sans Pro";
  line-height: 3;
  margin-left: 20px;

  &:hover {
    color: #64d2d4;
    font-size: 18px;
  }
`;

const QuoteButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 160px;
  background-color: #80AA7B;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-right: 25px;
  margin-left: 25px;
  font-family: "Source Sans Pro";
  font-weight: bold;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #111d57;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const LoginAndAccountButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 160px;
  background-color: #111d57;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 25px;
  font-family: "Source Sans Pro";
  font-weight: bold;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #80AA7B; 
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const HamburgerIcon = styled(FiMenu)`
  display: none;

  @media (max-width: 768px) {
    display: block; // Only show on mobile screens
    cursor: pointer;
    margin-right: 50px;
  }
`;

const Navbar = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const checkLoggedIn = async () => {
      const { data: { user } } = await supabase.auth.getUser()
      console.log("user return val", user);

      if (user && user !== null && user !== undefined) {
        setIsSignedIn(true);
      }
    }
    checkLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const handleClick = () => {
    navigate("/get-quote");
    mixpanel.track("Get quote clicked from navbar");
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };
  
  const LoginClick = () => {
    navigate("/login-page");
    mixpanel.track("User clicked log in");
    window.scrollTo(0, 0);
  };
  
  const userDashboardClick = () => {
    navigate("/user-dashboard");
    mixpanel.track("User clicked go to user dashboard");
    window.scrollTo(0, 0);
  };

  const handleLoginButtonClick = () => {
    if (isSignedIn) {
      userDashboardClick();
    } else {
      LoginClick();
    }
  };

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <NavbarContainer>
      <GlobalStyles /> {/* Ensure this is used here */}
      <Link to="/" onClick={handleLogoClick}>
        <Logo src={logo} alt="EcoTrove Logo" />
      </Link>
      <NavLinks>
        <NavLink href="/how-it-works">How it works</NavLink>
        <NavLink href="/faqs">FAQs</NavLink>
        <NavLink href="/our-team">Our Team</NavLink>
        <NavLink href="/all-blogs">Blog</NavLink>
      </NavLinks>
      <LoginAndAccountButton onClick={handleLoginButtonClick}>
        {isSignedIn ? "My Account" : "Login"}
      </LoginAndAccountButton>
      <QuoteButton onClick={handleClick}>Check savings</QuoteButton>
      <HamburgerIcon size={24} onClick={toggleMobileMenu} />
      {isMobileMenuOpen && (
        <MobileMenuContainer>
          <NavLink onClick={closeMobileMenu} href="how-it-works">
            How it works
          </NavLink>
          <NavLink href="/faqs">FAQs</NavLink>
          <NavLink href="/our-team">Our team</NavLink>
          <NavLink onClick={closeMobileMenu} href="all-blogs">
            Blog
          </NavLink>
          <NavLink href={isSignedIn ? "/user-dashboard" : "/login-page"}>
            {isSignedIn ? "My Account" : "Log-in"}
          </NavLink>
          <NavLink href="/get-quote">Check savings</NavLink>
        </MobileMenuContainer>
      )}
    </NavbarContainer>
  );
};

export default Navbar;
